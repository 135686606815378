import React, { useEffect, useState, useCallback, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Skeleton } from "primereact/skeleton";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import { Dialog } from 'primereact/dialog';
import { useGetFileUrl } from "../../helpers/hooks/useGetFIleUrl";
import authFetch from "../../axios";
import { Toast } from "primereact/toast";
import useModuleAccess from "../../helpers/hooks/useModuleAccess";
import { Permissions } from "../../helpers/constants";

const BirthAttestationCertificateReissuanceDetails = () => {
    const { checkModuleAccess } = useModuleAccess();
    const canApproveAttestReissuance = checkModuleAccess(Permissions.canApproveAttestReissuance);
    const canQueryAttestReissaunce = checkModuleAccess(Permissions.canQueryAttestReissaunce);
    const toast = useRef(null);
    const { id } = useParams();
    const [details, setDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);


    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

    const [modalType, setModalType] = useState(null); // "approve" | "query"
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [queryReason, setQueryReason] = useState("");


    const { getUrl: getFileUrl, isLoadingUrl: isLoadingFileUrl } = useGetFileUrl();
    const [documentUrls, setDocumentUrls] = useState([]);

    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    // Fetch certificate details
    const fetchDetails = useCallback(async () => {
        try {
            setIsLoading(true);
            setHasError(false);
            const response = await authFetch.post(`/certificate-reissuance/single`, {
                certType: "attest_reissuance",
                id: Number(id),
            });
            const data = response?.data?.data;
            setDetails(data);

            // Fetch file URLs
            // if (data?.supportingDocument) {
            //     const urls = await Promise.all(
            //         data.supportingDocument
            //             .filter(doc => doc.file) // Only fetch URLs for documents with a file
            //             .map(doc => getFileUrl(doc.file))
            //     );
            //     setDocumentUrls(urls);
            // }
        } catch (error) {
            console.error("Error fetching details:", error);
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    }, [id]);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        fetchDetails();
    }, [fetchDetails]);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        fetchDetails();
    }, [fetchDetails, id]);

    const handleAction = async (action) => {
        setIsProcessing(true);
        try {
            const payload = {
                certType: "attest_reissuance",
                id: Number(id),
                action,
                ...(action === "reject" && { reason: queryReason }),
            };
            await authFetch.post(
                `/certificate-reissuance/${action === "approve" ? "approve" : "reject"}`,
                payload
            );

            toast.current.show({
                severity: "success",
                summary: `${action === "approve" ? "Approval" : "Query"} Successful`,
                detail: `The registration was successfully ${action === "approve" ? "approved" : "queried"}.`,
                life: 5000,
            });

            // Refresh details
            await fetchDetails();

            // Show the success modal
            setIsSuccessModalOpen(true);
            setIsModalOpen(false);
        } catch (error) {
            toast.current.show({
                severity: "error",
                summary: `${action === "approve" ? "Approval" : "Query"} Failed`,
                detail: error?.response?.data?.message || "An unexpected error occurred. Please try again.",
                life: 5000,
            });
        } finally {
            setIsProcessing(false);
        }
    };

    const renderActionButtons = () => {
        if (details?.approvedStatus === "0" && !details?.queried) {
            return (
                <div className="mt-3 lg:mt-0">
                    {canApproveAttestReissuance && (
                        <>
                            <Button
                                label="Approve"
                                className="mr-2 p-button-raised"
                                style={{ width: "150px" }}
                                onClick={() => {
                                    setModalType("approve");
                                    setIsModalOpen(true);
                                }}
                            />
                        </>
                    )}
                    {canQueryAttestReissaunce && (
                        <>
                            <Button
                                label="Query"
                                className="p-button-danger p-button-raised"
                                style={{ width: "150px" }}
                                onClick={() => {
                                    setModalType("query");
                                    setIsModalOpen(true);
                                }}
                            />
                        </>
                    )}

                </div>
            );
        }
        return null;
    };

    const renderDataField = (label, value) => (
        <div className="col-12 md:col-3 mb-2">
            <div className="text-600 text-xs">{label}</div>
            <div className="text-900 font-medium">
                {value ? (
                    value
                ) : (
                    <span className="text-gray-500 text-xs italic flex items-center">
                        <i className="pi pi-exclamation-triangle mr-1 text-red-500" style={{ fontSize: '1rem' }}></i>
                        Data not provided
                    </span>
                )}
            </div>
        </div>
    );

    if (isLoading) {
        return (
            <div className="px-2">
                <Button
                    label="Back"
                    icon="pi pi-arrow-left"
                    className="p-button-text p-button-secondary mb-2 p-button-rounded"
                    onClick={goBack}
                />
                <div className="px-4 py-4 shadow-2 border-round surface-0 mb-4">
                    <Skeleton borderRadius="16px" className="mb-2" width="50%" height="1.5rem" />
                    <Skeleton borderRadius="16px" className="mb-6" width="30%" height="0.5rem" />
                    <div className="grid">
                        {Array(8).fill(null).map((_, i) => (
                            <div className="col-12 md:col-3 mb-4" key={i}>
                                <Skeleton borderRadius="16px" width="100%" height="1.5rem" className="mb-2" />
                                <Skeleton borderRadius="16px" width="80%" height="1rem" />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="px-4 py-4 shadow-2 border-round surface-0 mb-4">
                    <Skeleton borderRadius="16px" className="mb-2" width="50%" height="1.5rem" />
                    <Skeleton borderRadius="16px" className="mb-6" width="30%" height="0.5rem" />
                    <div className="grid">
                        {Array(8).fill(null).map((_, i) => (
                            <div className="col-12 md:col-3 mb-4" key={i}>
                                <Skeleton borderRadius="16px" width="100%" height="1.5rem" className="mb-2" />
                                <Skeleton borderRadius="16px" width="80%" height="1rem" />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="px-4 py-4 shadow-2 border-round surface-0 mb-4">
                    <Skeleton borderRadius="16px" className="mb-2" width="50%" height="1.5rem" />
                    <Skeleton borderRadius="16px" className="mb-6" width="30%" height="0.5rem" />
                    <div className="grid">
                        {Array(8).fill(null).map((_, i) => (
                            <div className="col-12 md:col-3 mb-4" key={i}>
                                <Skeleton borderRadius="16px" width="100%" height="1.5rem" className="mb-2" />
                                <Skeleton borderRadius="16px" width="80%" height="1rem" />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }

    if (hasError) {
        return (
            <div className="px-2">
                <Button label="Back" icon="pi pi-arrow-left" className="p-button-text p-button-secondary mb-2 p-button-rounded" onClick={goBack} />
                <div className="p-4 w-full mx-auto">
                    <div className="text-700 text-center space-y-4">
                        <div className="text-red-600 text-3xl mb-3">
                            <i className="pi pi-exclamation-circle" style={{ fontSize: '2rem' }}></i>
                        </div>
                        <p className="text-red-700 font-semibold text-lg">
                            Failed to load details. Please try again later.
                        </p>
                        <Button
                            onClick={() => window.location.reload()}
                            label="Try Again"
                            icon="pi pi-refresh"
                            className="font-bold px-5 py-3 p-button-danger p-button-raised p-button-rounded white-space-nowrap"
                        />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            {/* Toast Notifications */}
            <Toast ref={toast} />

            {/* Approve/Query Modal */}
            {modalType && (
                <Dialog
                    visible={isModalOpen}
                    style={{ width: "50vw", borderRadius: "10px" }}
                    onHide={() => setIsModalOpen(false)}
                >
                    {isProcessing ? (
                        <div className="text-center pb-6">
                            <i className="pi pi-spin pi-spinner mb-4 text-primary" style={{ fontSize: "4rem" }}></i>
                            <div className="text-primary text-xl">
                                {modalType === "approve"
                                    ? "Approving registration..."
                                    : "Querying registration..."}
                            </div>
                        </div>
                    ) : modalType === "approve" ? (
                        <div className="text-center">
                            <i className="pi pi-question-circle text-primary mb-4" style={{ fontSize: "4rem" }}></i>
                            <div className="text-primary text-2xl mb-5 font-medium">
                                Do you want to approve this registration?
                            </div>
                            <Button
                                label="Yes"
                                className="px-8 p-button-rounded mr-4"
                                onClick={() => handleAction("approve")}
                            />
                            <Button
                                label="No"
                                className="px-8 p-button-rounded p-button-outlined"
                                onClick={() => setIsModalOpen(false)}
                            />
                        </div>
                    ) : (
                        <>
                            <div className="text-primary text-xl mb-4 font-medium">
                                State Reasons for Query
                            </div>
                            <textarea
                                rows="5"
                                maxLength="500"
                                placeholder="Type here..."
                                value={queryReason}
                                onChange={(e) => setQueryReason(e.target.value)}
                                className="w-full p-3 border-1 border-gray-300 border-round text-700 bg-gray-100"
                            ></textarea>
                            <div className="flex justify-content-center mt-4">
                                <Button
                                    label="Submit"
                                    className="px-8 p-button-rounded mr-3"
                                    disabled={!queryReason.trim()}
                                    onClick={() => handleAction("reject")}
                                />
                                <Button
                                    label="Cancel"
                                    className="px-8 p-button-rounded p-button-outlined"
                                    onClick={() => setIsModalOpen(false)}
                                />
                            </div>
                        </>
                    )}
                </Dialog>
            )}

            {/* Success Modal */}
            <Dialog
                visible={isSuccessModalOpen}
                style={{ width: "40vw", borderRadius: "10px" }}
                onHide={() => setIsSuccessModalOpen(false)}
            >
                <div className="text-center">
                    <i className="pi pi-check-circle text-primary mb-4" style={{ fontSize: "4rem" }}></i>
                    <div className="text-primary text-2xl mb-5 font-medium">
                        Registration Details Successfully Updated!
                    </div>
                    <Button
                        label="Okay"
                        className="px-8 p-button-rounded"
                        onClick={() => setIsSuccessModalOpen(false)}
                    />
                </div>
            </Dialog>
            <Button label="Back" icon="pi pi-arrow-left" className="p-button-text p-button-secondary mb-2 p-button-rounded" onClick={goBack} />
            <div className="px-2">
                <div className="bg-yellow-600 px-4 py-2 shadow-2 border-round mb-4">
                    <div className="text-white font-bold">Registration Details</div>
                </div>

                <div>
                    {/* Applicant's Data */}
                    <div className="surface-0 px-4 py-4 shadow-2 border-round mb-4">
                        {/* Buttons */}
                        <div className="mb-4">
                            <div className="font-bold text-800">Particulars of Applicant</div>
                            <div className="bg-blue-200" style={{ height: '2px', width: '70px' }}></div>
                        </div>
                        <div className="grid">
                            {renderDataField("Surname of Applicant", details?.applicantSurname)}
                            {renderDataField("First Name of Applicant", details?.applicantFirstName)}
                            {renderDataField("Middle Name of Applicant", details?.applicantMiddleName)}
                            {renderDataField("Reference", details?.reference)}
                            {renderDataField("Date of Birth", details?.applicantDateOfBirth)}
                            {renderDataField("Sex", details?.applicantSexData?.gender)}
                            {renderDataField("Place of Birth", details?.placeOfBirthData?.BirthPlace_Desc)}
                        </div>
                        <div className="text-600 text-xs mb-2">Child Face</div>
                        {isLoadingFileUrl ? (
                            <Skeleton width="150px" height="150px" />
                        ) : details?.face ? (
                            <Image src={details?.face} zoomSrc={details?.face} className="shadow-2 border-round p-1 pb-0" alt="Child Face" preview width="150px" height="150px" />
                        ) : (
                            <p>No face image available.</p>
                        )}
                    </div>

                    {/* Mother's Data */}
                    <div className="surface-0 px-4 py-4 shadow-2 border-round mb-4">
                        <div className="mb-4">
                            <div className="font-bold text-800">Particulars of Mother</div>
                            <div className="bg-blue-200" style={{ height: '2px', width: '70px' }}></div>
                        </div>
                        <div className="grid">
                            {renderDataField("Name", details?.motherName)}
                            {renderDataField("State", details?.motherStateData?.State_Name)}
                            {renderDataField("L.G.A", details?.motherLgaData?.LGA_Name)}
                        </div>
                    </div>

                    {/* Father's Data */}
                    <div className="surface-0 px-4 py-4 shadow-2 border-round mb-4">
                        <div className="mb-4">
                            <div className="font-bold text-800">Particulars of Father</div>
                            <div className="bg-blue-200" style={{ height: '2px', width: '70px' }}></div>
                        </div>
                        <div className="grid">
                            {renderDataField("Name", details?.fatherName)}
                            {renderDataField("State", details?.fatherStateData?.State_Name)}
                            {renderDataField("L.G.A", details?.fatherLgaData?.LGA_Name)}
                        </div>
                    </div>

                    {/* Additional Information */}
                    <div className="surface-0 px-4 py-4 shadow-2 border-round mb-4">
                        <div className="mb-4">
                            <div className="font-bold text-800">Additional Information</div>
                            <div className="bg-blue-200" style={{ height: '2px', width: '70px' }}></div>
                        </div>
                        <div className="grid">
                            {renderDataField("NIN", details?.applicantNin)}
                            {renderDataField("Legacy Certificate Number", details?.legacyCertNumber)}
                            {renderDataField(
                                "Approval Status",
                                details?.approvedStatus === "1"
                                    ? "Approved"
                                    : details?.approvedStatus === "2"
                                        ? "Queried"
                                        : "Pending"
                            )}
                        </div>
                    </div>

                    {/* Uploaded Document Information */}
                    <div className="surface-0 px-4 py-4 shadow-2 border-round mb-4">
                        <div className="mb-4">
                            <div className="font-bold text-800">Uploaded Documents</div>
                            <div className="bg-blue-200" style={{ height: '2px', width: '70px' }}></div>
                        </div>
                        <div className="grid capitalize">
                            {details?.supportingDocument
                                ?.filter(doc => doc.type && doc.file && doc.mimeType) // Filter out empty documents
                                .map((doc, index) => (
                                    <div key={index} className="col-12 md:col-4 mb-4">
                                        <div className="w-full mb-2">
                                            <div className="text-600 text-xs">{doc.type}</div>
                                            <div className="text-900 font-medium"></div>
                                        </div>
                                        <div>
                                            {/* Render preview images for each document */}
                                            {isLoadingFileUrl ? (
                                                <Skeleton width="150px" height="150px" />
                                            ) : (
                                                <Image
                                                    src={doc.file}
                                                    className="shadow-2 border-round p-1 pb-0"
                                                    alt={doc.type}
                                                    preview
                                                    width="150px"
                                                    height="150px"
                                                />
                                            )}
                                        </div>
                                    </div>
                                ))}
                            {details?.legacyCertLink && (
                                <div className="col-12 md:col-4 mb-4">
                                    <div className="w-full mb-2">
                                        <div className="text-600 text-xs">Old Certificate</div>
                                    </div>
                                    <div>
                                        {/* Render preview images for the old certificate */}
                                        {isLoadingFileUrl ? (
                                            <Skeleton width="150px" height="150px" />
                                        ) : (
                                            <Image
                                                src={details?.legacyCertLink}
                                                className="shadow-2 border-round p-1 pb-0"
                                                alt="Old Certificate"
                                                preview
                                                width="150px"
                                                height="150px"
                                            />
                                        )}
                                    </div>
                                </div>
                            )}
                            {details?.signature && (
                                <div className="col-12 md:col-4 mb-4">
                                    <div className="w-full mb-2">
                                        <div className="text-600 text-xs">Signature</div>
                                    </div>
                                    <div>
                                        {isLoadingFileUrl ? (
                                            <Skeleton width="150px" height="150px" />
                                        ) : (
                                            <img
                                                src={details?.signature}
                                                className="shadow-2 border-round p-1 pb-0 object-cover"
                                                alt="Old Certificate"
                                                width="150px"
                                                height="150px"
                                            />
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>




                <div className="">
                    <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
                        <div>
                        </div>
                        <div className="mt-3 lg:mt-0">
                            {renderActionButtons()}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BirthAttestationCertificateReissuanceDetails;