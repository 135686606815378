import React from 'react';
import Logo from "../assets/images/72.png";

function GlobalSpinner({ text }) {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-70">
      <div className="flex flex-col items-center">
        {/* Animated Loader Container */}
        <div className="relative w-28 h-28">
          {/* Pulsating Background */}
          <div className="absolute inset-0 rounded-full bg-[#3d876a] opacity-20 animate-ping"></div>

          {/* Rotating Outer Ring */}
          <div className="absolute inset-0 animate-spin rounded-full border-4 border-t-[#3d876a] border-b-transparent border-l-[#dcfce7] border-r-[#dcfce7]"></div>

          {/* Center Glow Effect */}
          <div className="absolute inset-2 rounded-full bg-white shadow-lg shadow-green-800"></div>

          {/* Logo in the Center */}
          <div className="absolute inset-4 flex justify-center items-center rounded-full">
            <div className="p-2 bg-white rounded-full">
              <img
                src={Logo}
                alt="VITALREG Logo"
                className="w-12 h-12"
                aria-label="Loading Logo"
              />
            </div>
          </div>
        </div>

        {/* Loading Message */}
        <div className="text-center mt-6">
          <p className="text-lg font-semibold text-white">{text}</p>
        </div>
      </div>
    </div>
  );
}

export default GlobalSpinner;