import ReactDOM from "react-dom";
// import "./index.css";
import 'sweetalert2/dist/sweetalert2.min.css'
import store from "./redux/store";
import { Provider } from "react-redux";
import App from "./App";
import { Toaster } from "react-hot-toast";
import { GlobalSpinnerProvider } from "./providers/GlobalSpinnerProvider";

ReactDOM.render(
  <Provider store={store}>
    <GlobalSpinnerProvider>
      <Toaster
        toastOptions={{
          className: "uppercase text-xs",
          duration: 4000,
        }}
      />
      <App />
    </GlobalSpinnerProvider>
  </Provider>,
  document.getElementById("root")
);
