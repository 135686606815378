import React from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import { handleChange, clearFilters } from "../redux/birthSlice";
import { typeOptions } from "../utils";

const Filter4 = () => {
  const dispatch = useDispatch();
  const { search, type } = useSelector((state) => state.birth);

  const clearForm = () => {
    dispatch(clearFilters());
  };

  return (
    <>
      <div className="justify-between items-center bg-white p-6 border-round shadow-1 border">
        <div className="grid formgrid">
          <div className="col-12 mb-2 lg:col-4 lg:mb-0">
            <div className="flex-1">
              <label htmlFor="search" className="text-xs text-gray-600 mb-1 block">
                Keyword Search
              </label>
              <span className="p-input-icon-right w-full">
                <InputText id="search"
                  value={search}
                  onChange={(e) =>
                    dispatch(handleChange({ name: "search", value: e.target.value }))
                  }
                  placeholder="Enter keyword..."
                  className="w-full p-2 border border-gray-300 rounded-md" />
                <i className="pi pi-search" />
              </span>
            </div>
          </div>
          <div className="col-12 mb-2 lg:col-4 lg:mb-0">
            <div className="flex-1">
              <label htmlFor="type" className="text-xs text-gray-600 mb-1 block">
                Record Status
              </label>
              <Dropdown
                id="type"
                value={type}
                options={typeOptions}
                onChange={(e) =>
                  dispatch(handleChange({ name: "type", value: e.value }))
                }
                className="w-full border border-gray-300 rounded-md"
                placeholder="Select Status"
              />
            </div>
          </div>
          <div className="col-12 mb-2 lg:col-4 lg:mb-0">
            <label htmlFor="self" className="text-xs text-gray-600 mb-1 block">
              Action
            </label>
            <Button label="Reset Filter" icon="pi pi-filter-fill" className="px-5 white-space-nowrap p-button-warning w-full" onClick={clearForm} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Filter4;