import * as xlsx from "xlsx/xlsx.mjs";
import { saveAs } from "file-saver";
import {
  OrganizationVerificationLevels,
  RegTypeOptions,
  Roles,
  modField,
  roleOptions,
} from "./helpers/constants";
import { getGender } from "./redux/apiCalls";

export const dataOptions = {
  gender: [
    { Gender_ID: "1", Gender: "Male", Desc: "MALE - MAN" },
    { Gender_ID: "2", Gender: "Female", Desc: "FEMALE - WOMAN" },
  ],
  birthOrder: [
    { Birth_Order_ID: 1, Birth_Order: "1", Desc: "1ST CHILD" },
    { Birth_Order_ID: 2, Birth_Order: "2", Desc: "2ND CHILD" },
    { Birth_Order_ID: 3, Birth_Order: "3", Desc: "3RD CHILD" },
    { Birth_Order_ID: 4, Birth_Order: "4", Desc: "4TH CHILD" },
    { Birth_Order_ID: 5, Birth_Order: "5", Desc: "5TH CHILD" },
    { Birth_Order_ID: 6, Birth_Order: "6", Desc: "6TH CHILD" },
    { Birth_Order_ID: 7, Birth_Order: "7", Desc: "7TH CHILD" },
    { Birth_Order_ID: 8, Birth_Order: "8", Desc: "8TH CHILD" },
    { Birth_Order_ID: 9, Birth_Order: "9", Desc: "9TH CHILD" },
    { Birth_Order_ID: 10, Birth_Order: "10", Desc: "10TH CHILD" },
    { Birth_Order_ID: 11, Birth_Order: "11", Desc: "11TH CHILD" },
    { Birth_Order_ID: 12, Birth_Order: "12", Desc: "12TH CHILD" },
    { Birth_Order_ID: 13, Birth_Order: "13", Desc: "13TH CHILD" },
    { Birth_Order_ID: 14, Birth_Order: "14", Desc: "14TH CHILD" },
    { Birth_Order_ID: 15, Birth_Order: "15", Desc: "15TH CHILD" },
    { Birth_Order_ID: 16, Birth_Order: "16", Desc: "16TH CHILD" },
    { Birth_Order_ID: 17, Birth_Order: "17", Desc: "17TH CHILD" },
    { Birth_Order_ID: 18, Birth_Order: "18", Desc: "18TH CHILD" },
    { Birth_Order_ID: 19, Birth_Order: "19", Desc: "19TH CHILD" },
    { Birth_Order_ID: 20, Birth_Order: "20", Desc: "20TH CHILD" },
  ],
  birthType: [
    { Birth_Type_ID: 1, Description: "Single" },
    { Birth_Type_ID: 2, Description: "Twins" },
    { Birth_Type_ID: 3, Description: "Triplets" },
    { Birth_Type_ID: 4, Description: null },
    { Birth_Type_ID: 5, Description: null },
    { Birth_Type_ID: 6, Description: null },
  ],
  birthPlace: [
    { BirthPlace_ID: "1", BirthPlace_Desc: "Hospital" },
    { BirthPlace_ID: "2", BirthPlace_Desc: "At Home" },
    { BirthPlace_ID: "3", BirthPlace_Desc: "Maternity Home" },
    { BirthPlace_ID: "4", BirthPlace_Desc: "Car/Taxi" },
    { BirthPlace_ID: "5", BirthPlace_Desc: "Bus" },
    { BirthPlace_ID: "6", BirthPlace_Desc: "Traditional Maternity Homes" },
    { BirthPlace_ID: "7", BirthPlace_Desc: "Train" },
    { BirthPlace_ID: "8", BirthPlace_Desc: "Spritual Homes" },
    { BirthPlace_ID: "9", BirthPlace_Desc: "Traditional Birth Attendance" },
    { BirthPlace_ID: "10", BirthPlace_Desc: "Others" },
    { BirthPlace_ID: "11", BirthPlace_Desc: "Roadside" },
    { BirthPlace_ID: "12", BirthPlace_Desc: "Traditional Herbalist" },
    { BirthPlace_ID: "13", BirthPlace_Desc: "Ship" },
    { BirthPlace_ID: "14", BirthPlace_Desc: "Aeroplane" },
  ],
};

export const approvalStatus = {
  PENDING: 0,
  APPROVED: 1,
  QUERIED: 2,
};
export const modificationStatus = {
  NEUTRAL: 0,
  PENDING: 1,
  APPROVED: 2,
  QUERIED: 3,
};

export function truncateText(text, maxLength = 20) {
  return text?.length > maxLength ? text?.slice(0, maxLength) + "..." : text;
}

export const exportExcel = (filename) => {
  // import('xlsx').then(xlsx => {

  // Find the table element by class name
  var table_elt = document.querySelector(".p-datatable-table");

  // Check if the table element is found
  if (table_elt) {
    // Clone the table to avoid modifying the original
    const clonedContainer = table_elt.cloneNode(true);

    // Remove <span> elements within <td> elements
    const spansToRemove = clonedContainer.querySelectorAll("td span");
    spansToRemove.forEach((span) => {
      span.parentNode.removeChild(span);
    });

    // Convert the modified table to a worksheet
    const worksheet = xlsx.utils.table_to_sheet(clonedContainer);

    // Create a workbook
    const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };

    // Convert the workbook to Excel format
    const excelBuffer = xlsx.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Save the Excel file
    saveAsExcelFile(excelBuffer, filename);
  } else {
    console.error("Table element not found");
  }
  // });
};
const saveAsExcelFile = (buffer, fileName) => {
  // import("file-saver").then((FileSaver) => {
  let EXCEL_TYPE =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  let EXCEL_EXTENSION = ".xlsx";
  const data = new Blob([buffer], {
    type: EXCEL_TYPE,
  });
  saveAs(data, fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION);
};

export const typeOptions = [
  { label: "Approved", value: "approved" },
  { label: "Queried", value: "queried" },
  { label: "Pending", value: "pending" },
];

export const validateFields = (state) => {
  const raw = Object.entries(state).filter((prop) => prop[1] === "");
  const errs = raw.map((i) => ({ [i[0]]: i[1] }));
  return Object.assign({}, ...errs);
};

export const getRoleNameById = (id) => {
  return roleOptions.find((roles) => +roles.id === +id).name;
};

export const getChildRoleNameByRoleId = (role) => {
  let roleName;
  switch (role) {
    case Roles.DCR:
      roleName = getRoleNameById(Roles.Registrar);
      break;

    case Roles.StateDirector:
      roleName = getRoleNameById(Roles.DCR);
      break;
    case Roles.Registrar:
      roleName = getRoleNameById(Roles.Adhoc);
      break;

    default:
      roleName = "unknown";
      break;
  }

  return roleName;
};

export const reverse_option_value = (value, data) => {
  let result = data.find((x) => +x.value === +value);
  return result?.name || "";
};

export const getBgcolor = (status) => {
  let color;
  switch (status) {
    case approvalStatus.APPROVED:
      color = "bg-green-600";
      break;
    case approvalStatus.QUERIED:
      color = "bg-red-600";
      break;
    case approvalStatus.PENDING:
      color = "bg-yellow-600";
      break;

    default:
      color = "bg-yellow-600";
      break;
  }
  return color;
};
export const getModBgcolor = (status) => {
  let color;
  switch (status) {
    case modificationStatus.APPROVED:
      color = "bg-green-600";
      break;
    case modificationStatus.QUERIED:
      color = "bg-red-600";
      break;
    case modificationStatus.PENDING:
      color = "bg-yellow-600";
      break;

    default:
      color = "bg-yellow-600";
      break;
  }
  return color;
};
export const getApprovalStatusKey = (value) => {
  const key = Object.keys(approvalStatus).find(
    (key) => approvalStatus[key] === value
  );
  return key || "Unknown Status";
};

export const getDetailsForModificationType = (modFieldType, record) => {
  const concatNameParts = (surname, middlename, firstname) =>
    `${surname ?? ""} ${middlename ?? ""} ${firstname ?? ""}`.trim();

  switch (modFieldType) {
    case modField.child_name:
      return {
        newDetails: concatNameParts(
          record?.child_surname,
          record?.child_middlename,
          record?.child_firstname
        ),
        OldDetails: concatNameParts(
          record?.child_prev_surname,
          record?.child_prev_middlename,
          record?.child_prev_firstname
        ),
      };
    case modField.child_sex:
      return {
        newDetails: getGender(+record?.child_sex),
        OldDetails: getGender(+record?.child_prev_sex),
      };
    case modField.father_name:
      return {
        newDetails: concatNameParts(
          record?.father_surname,
          record?.father_middlename,
          record?.father_firstname
        ),
        OldDetails: concatNameParts(
          record?.father_prev_surname,
          record?.father_prev_middlename,
          record?.father_prev_firstname
        ),
      };
    case modField.mother_name:
      return {
        newDetails: `firstName\n: ${
          record?.mother_firstname ?? "--"
        },\nmiddleName: ${record?.mother_middlename ?? "--"},\nsurname: ${
          record?.mother_surname ?? "--"
        },\nmaiden name: ${record?.mother_maiden_name ?? "--"}`,
        OldDetails: `firstName: ${
          record?.mother_prev_surname ?? "--"
        },\nmiddleName: ${record?.mother_prev_middlename ?? "--"},\nsurname: ${
          record?.mother_prev_firstname ?? "--"
        },\nmaiden name: ${record?.mother_prev_maiden_name ?? "--"}`,
      };
    case modField.deceased_name:
      return {
        newDetails: concatNameParts(
          record?.deceased_surname,
          record?.deceased_middlename,
          record?.deceased_firstname
        ),
        OldDetails: concatNameParts(
          record?.deceased_prev_surname,
          record?.deceased_prev_middlename,
          record?.deceased_prev_firstname
        ),
      };
    case modField.deceased_sex:
      return {
        newDetails: getGender(+record?.deceased_sex),
        OldDetails: getGender(+record?.deceased_prev_sex),
      };
    case modField.deceased_age_at_death:
      return {
        newDetails: +record?.deceased_age_at_death,
        OldDetails: +record?.deceased_prev_age_at_death,
      };
    case modField.deceased_date_of_death:
      return {
        newDetails: record?.deceased_date_of_death,
        OldDetails: record?.deceased_prev_date_of_death,
      };
    case modField.applicant_name:
      return {
        newDetails: concatNameParts(
          record?.applicant_surname,
          record?.applicant_middlename,
          record?.applicant_firstname
        ),
        OldDetails: concatNameParts(
          record?.applicant_prev_surname,
          record?.applicant_prev_middlename,
          record?.applicant_prev_firstname
        ),
      };
    case modField.applicant_sex:
      return {
        newDetails: getGender(+record?.applicant_sex),
        OldDetails: getGender(+record?.applicant_prev_sex),
      };
    case modField.applicant_town:
      return {
        newDetails: record?.applicant_town_of_origin,
        OldDetails: record?.applicant_prev_town_of_origin,
      };
    case modField.applicant_face:
      return {
        newDetails: record?.applicant_face,
        OldDetails: record?.applicant_prev_face,
      };
    default:
      // Handle other cases or provide default values
      return { label: "Label", details: "Details" };
  }
};

export const regTypeFormat = (rowData) => {
  switch (+rowData?.self) {
    case RegTypeOptions.SelfService:
      return <p className={`font-bold`}>Self Service</p>;
    case RegTypeOptions.Assisted:
      return <p className={`font-bold`}>Assisted</p>;
    default:
      return <p className={`font-bold`}>Assisted</p>;
  }
};

export const genderTemplate = (rowData) => {
  return <p className={`font-bold`}>{getGender(rowData ?? "")}</p>;
};

export const dobTemplate = (rowData) => {
  return <p className={`font-bold`}>{rowData}</p>;
};

export const dateTemplate = (date) => {
  return (
    <p>
      {" "}
      {isNaN(Date.parse(date))
        ? "Invalid date"
        : new Date(date).toLocaleString("en-GB")}
    </p>
  );
};

export const SerialNumberTemplate = (cell, serials, records) => {
  if (!serials || serials.length === 0) return "-";
  const index = records.findIndex(record => record.id === cell.id);
  return index !== -1 ? serials[index] : "-";
};

export const statusFormat = (rowData) => {
  let status;
  switch (+rowData) {
    case approvalStatus.APPROVED:
      status = "Approved";
      break;
    case approvalStatus.PENDING:
      status = "Pending";
      break;
    case approvalStatus.QUERIED:
      status = "Queried";
      break;
    default:
      status = "Unknown";
  }
  return <p>{status}</p>;
};

export const getLevelsDetailsText = (selectedLevel) => {
  switch (selectedLevel) {
    case OrganizationVerificationLevels.level1:
      return "Name & Date of Birth";
    case OrganizationVerificationLevels.level2:
      return "Name, Date of Birth & Picture";
    case OrganizationVerificationLevels.level3:
      return "Name, Date of Birth, Gender & Picture";
    case OrganizationVerificationLevels.level4:
      return "Name, Date of Birth, Gender, Picture & Mother's Name & Father's Name";
    case OrganizationVerificationLevels.level5:
      return "All details except NIN";
    default:
      return "";
  }
};

export function roundToNearest(num) {
  // Determine the nearest rounding factor based on the number's size
  const length = num.toString().length;

  // If the number is small, round to nearest 10
  if (length === 1 || length === 2) {
    return Math.ceil(num / 10) * 10;
  }
  // If the number is larger, round to nearest 100, 1000, etc.
  else {
    const factor = Math.pow(10, length - 1);
    return Math.ceil(num / factor) * factor;
  }
}


export const mapRoleNameById = (id, stateAssigned) => {
  switch (id) {
    case Roles.Admin:
      return "Super Admin";
    case Roles.SubAdmin:
      return stateAssigned ? "State ICT" : "Admin";
    default: {
      const roleEntry = Object.entries(Roles).find(
        ([key, value]) => value === id
      );
      return roleEntry ? roleEntry[0] : null;
    }
  }
};

export function addSpacesToWords(text) {
  if (!text) return "";
  
  // Add space before uppercase letters (camel case) and replace underscores with spaces
  return text.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/_/g, ' ');
}


export const USER_ROLE = {
  STATE_DIRECTOR: "1",
  DCR: "2",
  REGISTRAR: "3",
  ADMIN: "5",
  SUBADMIN: "8",
};

export const RoleName = (roleId) => {
  switch (Number(roleId)) {
    case 1:
      return "State Director";
    case 2:
      return "DCR";
    case 3:
      return "Registrar";
    case 4:
      return "Adhoc";
    case 5:
      return "Admin";
    case 6:
      return "Auth user";
    case 7:
      return "Hospital";
    case 8:
      return "Sub Admin";
    case 9:
      return "TIU";
    case 10:
      return "State Commissioner";
    default:
      return "unknown";
  }
};
