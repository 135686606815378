import { useEffect, useState, useRef } from "react";
import { Button } from "primereact/button";
// import { FileUpload } from 'primereact/fileupload';
import authFetch from "../axios";
import { Toast } from "primereact/toast";
import { useNavigate, useParams } from "react-router-dom";
// import { useSearchParams } from "react-router-dom";
import { MultiSelect } from "primereact/multiselect";
import { ProgressSpinner } from "primereact/progressspinner";
import { Permissions, Roles, roleOptions } from "../helpers/constants";
import useModuleAccess from "../helpers/hooks/useModuleAccess";
import { useSelector } from "react-redux";
import ErrorMessageExtractor from "../helpers/utils/errorHandler";
import {
  getAllStates,
  getCenterByLgaId,
  getLgaByStateId,
  updateUserApi,
} from "../redux/apiCalls";
import { validateFields } from "../utils";
import FormLabel from "../components/FormLabel";
import _ from "lodash";
// import { ScatterController } from 'chart.js';
const UpdateUser = () => {
  // const [searchParams] = useSearchParams();
  const toast = useRef(null);
  const hasModuleAccess = useModuleAccess;
  const { id } = useParams();
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const [state, setStates] = useState([]);
  const [userState, setUserState] = useState("");
  const [userLga, setUserLga] = useState("");
  const [role, setRole] = useState("");
  const { userInfo } = useSelector((state) => state.user);
  const [userCenter, setUserCenter] = useState("");
  const [user, setUser] = useState({});
  // const [userDcr, setUserDcr] = useState("");
  const [lga, setLga] = useState([]);
  const [center, setCenter] = useState([]);
  // const [dcr, setDcr] = useState([]);
  const [nin, setNin] = useState("");
  const [username, setUsername] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [permissions, setPermissions] = useState([]);
  const [selectedPerm, setSelectedPerm] = useState(null);
  const [loading1, setLoading1] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [errors, setErrors] = useState({});

  // const val=['Can_Do_Attestation']
  const changeState = async (e) => {
    setUserState(e.target.value);
    const statz = await authFetch.get(`/option/lga/${e.target.value}`);
    setLga(statz.data);
    setCenter([]);
    setUserLga(null);
    setUserCenter(null);
  };

  let filteredRoleOptions = [...roleOptions];

  const permissionRoleMapping = {
    [Permissions.canManageAdhocs]: Roles.Adhoc,
    [Permissions.canManageDCR]: Roles.DCR,
    [Permissions.canManageRegistrar]: Roles.Registrar,
    [Permissions.canManageStateDirector]: Roles.StateDirector,
  };

  switch (userInfo.Role_ID) {
    case Roles.SubAdmin.toString():
      filteredRoleOptions = filteredRoleOptions.filter((option) =>
        [
          Roles.SubAdmin,
          Roles.StateDirector,
          Roles.DCR,
          Roles.Registrar,
          Roles.Adhoc,
        ].includes(option.id)
      );
      break;

    case Roles.TIU.toString():
      filteredRoleOptions = filteredRoleOptions.filter((option) =>
        [Roles.StateDirector, Roles.DCR, Roles.Registrar, Roles.Adhoc].includes(
          option.id
        )
      );
      break;
    case Roles.Admin.toString():
      filteredRoleOptions = filteredRoleOptions.filter((option) =>
        [
          Roles.StateDirector,
          Roles.DCR,
          Roles.Registrar,
          Roles.Adhoc,
          Roles.Admin,
          Roles.SubAdmin,
          Roles.TIU,
        ].includes(option.id)
      );
      break;
    default:
      filteredRoleOptions = filteredRoleOptions.filter((option) =>
        [].includes(option.id)
      );
      break;
  }

  if (userInfo) {
    Object.entries(permissionRoleMapping).forEach(([permission, roleId]) => {
      if (
        hasModuleAccess(Permissions.canManageUser) ||
        userInfo.Role_ID === Roles.Admin.toString()
      ) {
        return;
      } else if (!hasModuleAccess(permission)) {
        return (filteredRoleOptions = filteredRoleOptions.filter(
          (option) => option.id !== roleId
        ));
      }
    });
  }

  const changeRole = (e) => {
    setRole(e.target.value);
    if (e.target.value === "1") {
      setUserLga(null);
      setUserCenter(null);
      setUserLga(null);
      // setUserDcr(null);
    } else if (e.target.value === "2") {
      setUserCenter(null);
      // setUserDcr(null);
    } else if (e.target.value === "5") {
      setUserState(null);
      setUserLga(null);
      setUserCenter(null);
      setUserLga(null);
      // setUserDcr(null);
    }
  };

  const changeLga = async (e) => {
    setUserLga(e.target.value);
    if (userLga) {
      const statz = await authFetch.get(`/option/regcenter/${e.target.value}`);
      // const dcr = await authFetch.get(`/users/registra/dcr/${e.target.value}`);
      setCenter(statz.data);
      // setDcr(dcr.data);
    }
  };

  const create = async () => {
    const credentials = {
      nin: nin,
      username: username,
      phone: phone,
      email: email,
      state: userState,
      lga: userLga,
      center: userCenter,
      role: role,
    };
    const res = await validateFields(credentials);

    const roleFieldsMapping = {
      [Roles.DCR]: [
        "role",
        "email",
        "status",
        "username",
        "phone",
        "state",
        "lga",
        "nin",
      ],
      [Roles.Registrar]: [
        "email",
        "role",
        "status",
        "username",
        "phone",
        "state",
        "lga",
        "center",
        "nin",
      ],
      [Roles.StateDirector]: [
        "email",
        "role",
        "status",
        "username",
        "phone",
        "state",
        "nin",
      ],
      default: ["role", "email", "username", "phone", "status", "nin"],
    };
    const roleFields = roleFieldsMapping[+role] || roleFieldsMapping["default"];
    const tempErrors = Object.fromEntries(
      Object.entries(res).filter(([key]) => roleFields.includes(key))
    );

    setErrors(tempErrors);
    if (_.isEmpty(tempErrors)) {
      setLoadingUpdate(true);
      const data = {
        User_ID: parseInt(id),
        Email: email,
        Phone_No: phone,
        NIN: nin,
        State_ID: userState === null ? userState : parseInt(userState),
        LGA_ID: userLga === null ? userLga : parseInt(userLga),
        Reg_Center_ID: userCenter === null ? userCenter : parseInt(userCenter),
        Role_ID: parseInt(role),
        permissions: selectedPerm?.join(",") || null,
        is_active: "Active",
      };

      try {
        const updateUserResponse = await updateUserApi(data);
        setLoadingUpdate(false);
        if (updateUserResponse.success === true) {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: `${updateUserResponse.message}`,
          });
          setEmail("");
          setUserState("");
          setUserLga("");
          setNin("");
          setUsername("");
          setPhone("");
          setEmail("");
          setTimeout(() => {
            navigate(-1);
          }, 1500);
        }
      } catch (error) {
        console.log(error);
        setLoadingUpdate(false);
        ErrorMessageExtractor.errorParser(error);
        // toast.current.show({
        //   severity: "error",
        //   summary: "Error",
        //   detail: `${message ?? "Record not updated"}`,
        // });
      }
    }
  };

  useEffect(() => {
    const getStates = async () => {
      try {
        const getPermissions = await authFetch.get(`/option/permissions`);
        const user = await authFetch.get(`/users/single/${id}`);
        setPermissions(getPermissions.data);
        setUser(user.data);
      } catch (error) {
        const message = ErrorMessageExtractor.errorParser(error);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: `${message ?? "Record not Found"}`,
        });
      } finally {
        setLoading1(false);
      }
    };
    getStates();
  }, [id]);

  useEffect(async () => {
    if (user) {
      try {
        const data = await getAllStates();
        setStates(data);
      } catch (error) {
        console.log(error);
      }
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      setSelectedPerm(user?.permission?.permissions?.split(","));
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      setNin(user.nin);
      setUsername(user.UserName);
      setPhone(user.Phone_No);
      setEmail(user.Email);
      setRole(user.Role_ID);
      setUserCenter(user.Reg_Center_ID);
      // setUserDcr(user.dcr);
      setUserLga(user.LGA_ID);
      setUserState(user.State_ID);
      // console.log(user?.State_ID)
    }
  }, [user]);

  useEffect(async () => {
    if (user?.State_ID) {
      try {
        const data = await getLgaByStateId(user?.State_ID);
        setLga(data);
      } catch (error) {
        console.log(error);
      }
    }
  }, [user]);

  useEffect(async () => {
    if (user?.LGA_ID) {
      try {
        const data = await getCenterByLgaId(user?.LGA_ID);
        setCenter(data);
      } catch (error) {
        console.log(error);
      }
    }
  }, [user]);

  if (loading1) {
    return (
      <div className="flex justify-content-center align-items-center">
        <ProgressSpinner style={{ width: "3rem", height: "3rem" }} />
      </div>
    );
  }
  return (
    <div className="grid mt-2 p-4">
      <div className="col-12 lg:col-12">
        <div className="card border-round shadow-2 p-3">
          <Button
            label="Go back"
            icon="pi pi-arrow-left"
            className="p-button-sm my-3"
            onClick={goBack}
          />
          {/* <div className="card"> */}
          <h5 className="capitalize border-green-500 border-bottom-3 text-lg font-bold mb-3">
            update user
          </h5>
          <div className="formgrid grid">
            <div className="field col-12 md:col-4">
              {/* <label htmlFor="firstname6">Username</label> */}
              <FormLabel
                required
                label="User Name"
                type="username"
                errors={errors}
              />
              <input
                id="firstname6"
                value={username}
                type="text"
                onChange={(e) => setUsername(e.target.value)}
                className="text-sm text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
              />
            </div>
            <div className="field col-12 md:col-4">
              <FormLabel
                required
                label="Phone Number"
                type="phone"
                errors={errors}
              />
              <input
                id="lastname8"
                value={phone}
                type="text"
                onChange={(e) => setPhone(e.target.value)}
                className="text-sm text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                maxLength={11}
              />
            </div>

            <div className="field col-12 md:col-4">
              <FormLabel required label="NIN" type="nin" errors={errors} />
              <input
                id="nin"
                value={nin}
                type="text"
                onChange={(e) => setNin(e.target.value)}
                className="text-sm text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                maxLength={11}
              />
            </div>
            <div className="field col-12 md:col-4">
              <FormLabel
                required
                label="Email Address"
                type="email"
                errors={errors}
              />
              <input
                id="email"
                value={email}
                type="text"
                onChange={(e) => setEmail(e.target.value)}
                className="text-sm text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
              />
            </div>

            <div className="field col-12 md:col-4">
              <FormLabel required label="Role" type="role" errors={errors} />
              <select
                id="role"
                value={role}
                className="w-full text-sm text-color surface-overlay p-2 border-1 border-solid surface-border border-round outline-none focus:border-primary"
                onChange={changeRole}
                style={{ appearance: "auto" }}
              >
                <option value={""}>Select Role</option>
                {filteredRoleOptions.map((item, i) => {
                  return (
                    <option value={item.id} key={i}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </div>
            {[
              Roles.Registrar,
              Roles.DCR,
              Roles.StateDirector,
              Roles.Adhoc,
            ].includes(+role) && (
                <div className="field col-12 md:col-4">
                  <FormLabel
                    required
                    label="State"
                    type="state"
                    errors={errors}
                  />
                  <select
                    id="state"
                    value={userState}
                    className="w-full text-sm text-color surface-overlay p-2 border-1 border-solid surface-border border-round outline-none focus:border-primary"
                    onChange={changeState}
                    style={{ appearance: "auto" }}
                  >
                    <option value={""}>select state</option>
                    {state.map((item, i) => {
                      return (
                        <option value={item.State_ID} key={i}>
                          {item.State_Name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
            {[Roles.Registrar, Roles.DCR, Roles.Adhoc].includes(+role) && (
              <div className="field col-12 md:col-4">
                <FormLabel required label="LGA" type="lga" errors={errors} />
                <select
                  id="lga"
                  value={userLga}
                  onChange={changeLga}
                  className="w-full text-sm text-color surface-overlay p-2 border-1 border-solid surface-border border-round outline-none focus:border-primary"
                  style={{ appearance: "auto" }}
                >
                  <option value="">select lga</option>
                  {lga.map((item, i) => {
                    return (
                      <option value={item.LGA_ID} key={i}>
                        {item.LGA_Name}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
            {[Roles.Registrar, Roles.Adhoc].includes(+role) && (
              <div className="field col-12 md:col-4">
                <FormLabel
                  required
                  label="Center"
                  type="center"
                  errors={errors}
                />
                <select
                  id="cen"
                  value={userCenter}
                  onChange={(e) => setUserCenter(e.target.value)}
                  className="w-full text-sm text-color surface-overlay p-2 border-1 border-solid surface-border border-round outline-none focus:border-primary"
                  style={{ appearance: "auto" }}
                >
                  <option value="">select center</option>
                  {center.map((item, i) => {
                    return (
                      <option value={item.Reg_Center_id} key={i}>
                        {item.Reg_Center_Name}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
            {/* {role === "3" && (
              <div className="field col-12 md:col-4">
                <label htmlFor="state">DCR</label>
                <select
                  id="dcr"
                  value={userDcr}
                  onChange={(e) => setUserDcr(e.target.value)}
                  className="w-full text-sm text-color surface-overlay p-2 border-1 border-solid surface-border border-round outline-none focus:border-primary"
                  style={{ appearance: "auto" }}
                >
                  <option value="">select dcr</option>
                  {dcr.map((item, i) => {
                    return (
                      <option value={item.User_ID} key={i}>
                        {item.UserName}
                      </option>
                    );
                  })}
                </select>
              </div>
            )} */}

            <div className="formgrid grid px-2">
              <div className="field col-12 md:col-4">
                <label htmlFor="state">User Permissions</label>

                <MultiSelect
                  maxSelectedLabels={10}
                  value={selectedPerm}
                  onChange={(e) => setSelectedPerm(e.value)}
                  options={permissions}
                  placeholder="Select user permissions"
                  className="w-10rem xl:w-19rem text-sm"
                />
              </div>
            </div>

            <div className="field col-12 ">
              <Button
                label="Update user"
                loading={loadingUpdate}
                className="p-button-success my-2"
                onClick={create}
              />
            </div>
            <Toast ref={toast} position="top-center" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateUser;
