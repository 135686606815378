import authFetch from "../axios";
import {
  getZonesFailure,
  getZonesStart,
  getZonesSuccess,
  getStateFailure,
  getStateStart,
  getStateSuccess,
  getRegFailure,
  getRegStart,
  getRegSuccess,
  getLgaFailure,
  getLgaStart,
  getLgaSuccess,
  getCenterFailure,
  getCenterStart,
  getCenterSuccess,
  getOptionFailure,
  getOptionStart,
  getSexOptionSuccess,
  getTypeOptionSuccess,
  getOrderOptionSuccess,
  getPlaceOptionSuccess,
  getSingleRegFailure,
  getSingleRegStart,
  getSingleRegSuccess,
} from "./birthSlice";
import {
  getAttestZonesFailure,
  getAttestZonesStart,
  getAttestZonesSuccess,
  getAttestStateFailure,
  getAttestStateStart,
  getAttestStateSuccess,
  getAttestLgaFailure,
  getAttestLgaStart,
  getAttestLgaSuccess,
  getAttestCenterFailure,
  getAttestCenterStart,
  getAttestCenterSuccess,
  getAttestRegFailure,
  getAttestRegStart,
  getAttestRegSuccess,
  getAttestSingleRegFailure,
  getAttestSingleRegStart,
  getAttestSingleRegSuccess,
} from "./attestSlice";
// getStateFailure,getStateStart,getStateSuccess ,getRegFailure,getRegStart,getRegSuccess,getLgaFailure,getLgaStart,getLgaSuccess,getCenterFailure,getCenterStart,getCenterSuccess,getOptionFailure,getOptionStart,getSexOptionSuccess,getTypeOptionSuccess,getOrderOptionSuccess,getPlaceOptionSuccess,getSingleRegFailure,getSingleRegStart,getSingleRegSuccess
import {
  getDeathZonesFailure,
  getDeathZonesStart,
  getDeathZonesSuccess,
  getDeathStateFailure,
  getDeathStateStart,
  getDeathStateSuccess,
  getDeathLgaFailure,
  getDeathLgaStart,
  getDeathLgaSuccess,
  getDeathCenterFailure,
  getDeathCenterStart,
  getDeathCenterSuccess,
  getCauseOptionSuccess,
  getDeathRegFailure,
  getDeathRegStart,
  getDeathRegSuccess,
  getDeathSingleRegFailure,
  getDeathSingleRegStart,
  getDeathSingleRegSuccess,
} from "./deathSlice";
import {
  getStillZonesFailure,
  getStillZonesStart,
  getStillZonesSuccess,
  getStillStateFailure,
  getStillStateStart,
  getStillStateSuccess,
  getStillLgaFailure,
  getStillLgaStart,
  getStillLgaSuccess,
  getStillCenterFailure,
  getStillCenterStart,
  getStillCenterSuccess,
  getStillRegFailure,
  getStillRegStart,
  getStillRegSuccess,
  getStillSingleRegStart,
  getStillSingleRegSuccess,
  getStillSingleRegFailure,
} from "./stillSlice";
import { dataOptions } from "../utils";
import { Roles } from "../helpers/constants";
export const getZones = async (dispatch) => {
  dispatch(getZonesStart());
  try {
    const res = await authFetch.get(
      "/birth-registration/stats/birth-reg-by-geozone"
    );
    const newRes = res.data.map((it, i) => {
      if (it.Geo_Zone_Name === "North-Central") {
        return { ...it, img: "/assets/img/nc.png" };
      }
      if (it.Geo_Zone_Name === "South-East") {
        return { ...it, img: "/assets/img/se.png" };
      }

      if (it.Geo_Zone_Name === "North-East") {
        return { ...it, img: "/assets/img/ne.png" };
      }
      if (it.Geo_Zone_Name === "North-West") {
        return { ...it, img: "/assets/img/nw.png" };
      }
      if (it.Geo_Zone_Name === "South-South") {
        return { ...it, img: "/assets/img/ss.png" };
      }
      if (it.Geo_Zone_Name === "South-West") {
        return { ...it, img: "/assets/img/sw.png" };
      }
      return it;
    });
    dispatch(getZonesSuccess(newRes));
  } catch (error) {
    dispatch(getZonesFailure());
    console.log(error);
  }
  // clearAlert(dispatch);
};

export const getDeathZones = async (dispatch) => {
  dispatch(getDeathZonesStart());
  try {
    const res = await authFetch.get(
      `/death-registration/stats/birth-reg-by-geozone`
    );
    const newRes = res.data.map((it, i) => {
      if (it.Geo_Zone_Name === "North-Central") {
        return { ...it, img: "/assets/img/nc.png" };
      }
      if (it.Geo_Zone_Name === "South-East") {
        return { ...it, img: "/assets/img/se.png" };
      }
      if (it.Geo_Zone_Name === "North-East") {
        return { ...it, img: "/assets/img/ne.png" };
      }
      if (it.Geo_Zone_Name === "North-West") {
        return { ...it, img: "/assets/img/nw.png" };
      }
      if (it.Geo_Zone_Name === "South-South") {
        return { ...it, img: "/assets/img/ss.png" };
      }
      if (it.Geo_Zone_Name === "South-West") {
        return { ...it, img: "/assets/img/sw.png" };
      }
      return it;
    });
    dispatch(getDeathZonesSuccess(newRes));
  } catch (error) {
    dispatch(getDeathZonesFailure());
    console.log(error);
  }
  // clearAlert(dispatch);
};

export const getAttestZones = async (dispatch) => {
  dispatch(getAttestZonesStart());
  try {
    const res = await authFetch.get(
      `/birth-attestation/stats/birth-reg-by-geozone`
    );
    const newRes = res.data.map((it, i) => {
      if (it.Geo_Zone_Name === "North-Central") {
        return { ...it, img: "/assets/img/nc.png" };
      }
      if (it.Geo_Zone_Name === "South-East") {
        return { ...it, img: "/assets/img/se.png" };
      }
      if (it.Geo_Zone_Name === "North-East") {
        return { ...it, img: "/assets/img/ne.png" };
      }
      if (it.Geo_Zone_Name === "North-West") {
        return { ...it, img: "/assets/img/nw.png" };
      }
      if (it.Geo_Zone_Name === "South-South") {
        return { ...it, img: "/assets/img/ss.png" };
      }
      if (it.Geo_Zone_Name === "South-West") {
        return { ...it, img: "/assets/img/sw.png" };
      }
      return it;
    });
    dispatch(getAttestZonesSuccess(newRes));
  } catch (error) {
    dispatch(getAttestZonesFailure());
    console.log(error);
  }
  // clearAlert(dispatch);
};

export const getStillZones = async (dispatch) => {
  dispatch(getStillZonesStart());
  try {
    const res = await authFetch.get(`/stillbirth/stats/birth-reg-by-geozone`);
    const newRes = res.data.map((it, i) => {
      if (it.Geo_Zone_Name === "North-Central") {
        return { ...it, img: "/assets/img/nc.png" };
      }
      if (it.Geo_Zone_Name === "South-East") {
        return { ...it, img: "/assets/img/se.png" };
      }
      if (it.Geo_Zone_Name === "North-East") {
        return { ...it, img: "/assets/img/ne.png" };
      }
      if (it.Geo_Zone_Name === "North-West") {
        return { ...it, img: "/assets/img/nw.png" };
      }
      if (it.Geo_Zone_Name === "South-South") {
        return { ...it, img: "/assets/img/ss.png" };
      }
      if (it.Geo_Zone_Name === "South-West") {
        return { ...it, img: "/assets/img/sw.png" };
      }
      return it;
    });
    dispatch(getStillZonesSuccess(newRes));
  } catch (error) {
    dispatch(getStillZonesFailure());
    console.log(error);
  }
  // clearAlert(dispatch);
};

export const getStates = async (dispatch, id) => {
  dispatch(getStateStart());
  try {
    const res = await authFetch.get(
      `/birth-registration/stats/birth-reg-by-states/${id}`
    );

    dispatch(getStateSuccess(res.data));
  } catch (error) {
    dispatch(getStateFailure());
    console.log(error);
  }
  // clearAlert(dispatch);
};

export const getDeathStates = async (dispatch, id) => {
  dispatch(getDeathStateStart());
  try {
    const res = await authFetch.get(
      `/death-registration/stats/birth-reg-by-states/${id}`
    );
    dispatch(getDeathStateSuccess(res.data));
  } catch (error) {
    dispatch(getDeathStateFailure());
    console.log(error);
  }
  // clearAlert(dispatch);
};

export const getAttestStates = async (dispatch, id) => {
  dispatch(getAttestStateStart());
  try {
    const res = await authFetch.get(
      `/birth-attestation/stats/birth-reg-by-states/${id}`
    );
    dispatch(getAttestStateSuccess(res.data));
  } catch (error) {
    dispatch(getAttestStateFailure());
    console.log(error);
  }
  // clearAlert(dispatch);
};

export const getStillStates = async (dispatch, id) => {
  dispatch(getStillStateStart());
  try {
    const res = await authFetch.get(
      `/stillbirth/stats/birth-reg-by-states/${id}`
    );
    dispatch(getStillStateSuccess(res.data));
  } catch (error) {
    dispatch(getStillStateFailure());
    console.log(error);
  }
  // clearAlert(dispatch);
};

export const getLgas = async (dispatch, id) => {
  dispatch(getLgaStart());
  try {
    const res = await authFetch.get(
      `/birth-registration/stats/birth-reg-by-lga/${id}`
    );

    dispatch(getLgaSuccess(res.data));
  } catch (error) {
    dispatch(getLgaFailure());
    console.log(error);
  }
  // clearAlert(dispatch);
};

export const getAttestLgas = async (dispatch, id) => {
  dispatch(getAttestLgaStart());
  try {
    const res = await authFetch.get(
      `/birth-attestation/stats/birth-reg-by-lga/${id}`
    );

    dispatch(getAttestLgaSuccess(res.data));
  } catch (error) {
    dispatch(getAttestLgaFailure());
    console.log(error);
  }
  // clearAlert(dispatch);
};

export const getDeathLgas = async (dispatch, id) => {
  dispatch(getDeathLgaStart());
  try {
    const res = await authFetch.get(
      `/death-registration/stats/birth-reg-by-lga/${id}`
    );

    dispatch(getDeathLgaSuccess(res.data));
  } catch (error) {
    dispatch(getDeathLgaFailure());
    console.log(error);
  }
  // clearAlert(dispatch);
};

export const getStillLgas = async (dispatch, id) => {
  dispatch(getStillLgaStart());
  try {
    const res = await authFetch.get(`/stillbirth/stats/birth-reg-by-lga/${id}`);
    dispatch(getStillLgaSuccess(res.data));
  } catch (error) {
    dispatch(getStillLgaFailure());
    console.log(error);
  }
  // clearAlert(dispatch);
};

export const getCenters = async (dispatch, id) => {
  dispatch(getCenterStart());
  try {
    const res = await authFetch.get(
      `/birth-registration/stats/birth-reg-by-centre/${id}`
    );

    dispatch(getCenterSuccess(res.data));
  } catch (error) {
    dispatch(getCenterFailure());
    console.log(error);
  }
  // clearAlert(dispatch);
};

export const getAttestCenters = async (dispatch, id) => {
  dispatch(getAttestCenterStart());
  try {
    const res = await authFetch.get(
      `/birth-attestation/stats/birth-reg-by-centre/${id}`
    );

    dispatch(getAttestCenterSuccess(res.data));
  } catch (error) {
    dispatch(getAttestCenterFailure());
    console.log(error);
  }
  // clearAlert(dispatch);
};

export const getDeathCenters = async (dispatch, id) => {
  dispatch(getDeathCenterStart());
  try {
    const res = await authFetch.get(
      `/death-registration/stats/birth-reg-by-centre/${id}`
    );

    dispatch(getDeathCenterSuccess(res.data));
  } catch (error) {
    dispatch(getDeathCenterFailure());
    console.log(error);
  }
  // clearAlert(dispatch);
};

export const getStillCenters = async (dispatch, id) => {
  dispatch(getStillCenterStart());
  try {
    const res = await authFetch.get(
      `/stillbirth/stats/birth-reg-by-centre/${id}`
    );

    dispatch(getStillCenterSuccess(res.data));
  } catch (error) {
    dispatch(getStillCenterFailure());
    console.log(error);
  }
  // clearAlert(dispatch);
};

export const getRegz = async (
  dispatch,
  search,
  result_per_page,
  page,
  stateId,
  lgaId,
  centerId,
  Sex,
  Age,
  BirthType,
  BirthOrder,
  BirthPlace,
  dateFrom,
  dateTo
) => {
  dispatch(getRegStart());

  const data = {
    search: search,
    result_per_page: result_per_page,
    page: page,
    StateID: stateId,
    LGAID: lgaId,
    CenterId: centerId,
    Sex: Sex,
    Age: Age,
    BirthType: BirthType,
    BirthOrder: BirthOrder,
    BirthPlace: BirthPlace,
    DateFrom: dateFrom?.toString(),
    DateTo: dateTo?.toString(),
  };
  try {
    const res = await authFetch.get(`/birth-registration/`, {
      params: data,
    });

    dispatch(getRegSuccess(res.data));
    console.log(res.data);
  } catch (error) {
    dispatch(getRegFailure());
    console.log(error);
  }
};

export const getAttestRegz = async (
  dispatch,
  search,
  result_per_page,
  page,
  stateId,
  lgaId,
  centerId,
  Sex,
  Age,
  BirthType,
  BirthOrder,
  BirthPlace
) => {
  dispatch(getAttestRegStart());

  const data = {
    search: search,
    result_per_page: result_per_page,
    page: page,
    StateID: stateId,
    LGAID: lgaId,
    CenterId: centerId,
    Sex: Sex,
    Age: Age,
    BirthType: BirthType,
    BirthOrder: BirthOrder,
    BirthPlace: BirthPlace,
  };
  try {
    const res = await authFetch.get(`/birth-attestation/`, {
      params: data,
    });

    dispatch(getAttestRegSuccess(res.data));
    console.log(res.data);
  } catch (error) {
    dispatch(getAttestRegFailure());
    console.log(error);
  }
};

export const getStillRegz = async (
  dispatch,
  search,
  result_per_page,
  page,
  stateId,
  lgaId,
  centerId,
  Sex,
  Age,
  BirthPlace
) => {
  dispatch(getStillRegStart());

  const data = {
    search: search,
    result_per_page: result_per_page,
    page: page,
    StateID: stateId,
    LGAID: lgaId,
    CenterId: centerId,
    Sex: Sex,
    Age: Age,
    BirthPlace: BirthPlace,
  };
  try {
    const res = await authFetch.get(`/stillbirth/`, {
      params: data,
    });

    dispatch(getStillRegSuccess(res.data));
    console.log(res.data);
  } catch (error) {
    dispatch(getStillRegFailure());
    console.log(error);
  }
};

export const getDeathRegz = async (
  dispatch,
  search,
  result_per_page,
  page,
  stateId,
  lgaId,
  centerId,
  Sex,
  Cause
) => {
  dispatch(getDeathRegStart());

  const data = {
    search: search,
    result_per_page: result_per_page,
    page: page,
    StateID: stateId,
    LGAID: lgaId,
    CenterId: centerId,
    Sex: Sex,
    cause_of_death: Cause,
  };
  try {
    const res = await authFetch.get(`/death-registration/`, {
      params: data,
    });

    dispatch(getDeathRegSuccess(res.data));
    console.log(res.data);
  } catch (error) {
    dispatch(getDeathRegFailure());
    console.log(error);
  }
};

export const getRegs = async (dispatch, id) => {
  dispatch(getSingleRegStart());
  try {
    const res = await authFetch.get(`/birth-registration/${id}`);
    dispatch(getSingleRegSuccess(res.data.data));
    return res;
    // console.log(res.data.data)
  } catch (error) {
    dispatch(getSingleRegFailure());
    console.log(error);
  }
  // clearAlert(dispatch);
};

export const getStillRegs = async (dispatch, id) => {
  dispatch(getStillSingleRegStart());
  try {
    const res = await authFetch.get(`/stillbirth/${id}`);
    dispatch(getStillSingleRegSuccess(res.data.data));
    // console.log(res.data.data)
  } catch (error) {
    dispatch(getStillSingleRegFailure());
    console.log(error);
  }
  // clearAlert(dispatch);
};

export const getStillRegs2 = async (dispatch, id) => {
  dispatch(getAttestSingleRegStart());
  try {
    const res = await authFetch.get(`/birth-attestation/${id}`);
    dispatch(getAttestSingleRegSuccess(res.data.data));
    // console.log(res.data.data)
  } catch (error) {
    dispatch(getAttestSingleRegFailure());
    console.log(error);
  }
  // clearAlert(dispatch);
};

export const getDeathRegs = async (dispatch, id) => {
  dispatch(getDeathSingleRegStart());
  try {
    const res = await authFetch.get(`/death-registration/${id}`);
    dispatch(getDeathSingleRegSuccess(res.data.data));
    console.log(res.data.data);
  } catch (error) {
    dispatch(getDeathSingleRegFailure());
    console.log(error);
  }
  // clearAlert(dispatch);
};

export const getGender = (g) => {
  let res;
  const ans = dataOptions.gender.filter(
    (item) => g?.toString() === item.Gender_ID
  );
  res = ans[0]?.Gender;
  // console.log(res);
  return res;
};

export const getOrder = (order) => {
  let res;
  const ans = dataOptions.birthOrder.filter(
    (item) => order === item.Birth_Order
  );
  // console.log(ans,order)
  res = ans[0].Desc;
  //  console.log(res);
  return res;
};

export const getType = (type) => {};

// export const getCause=async()=>{
//   dispatch(getCauseStart());
//   try {
//     const res = await authFetch.get(`option/cause-of-death`);

//     const newRes = results[0].value.data.map((item,i)=>{

//       return {label: item.Gender, value: item.Gender_ID}
//       });
//       dispatch(getCauseSuccess(res.data.data));
//     // console.log(res.data.data)
//   } catch (error) {

//     dispatch(getCauseFailure());
//   console.log(error)
//   }
// }

export const getPlace = (place) => {
  let res;
  const ans = dataOptions.birthPlace.filter(
    (item) => place === item.BirthPlace_ID
  );
  console.log(ans);
  res = ans[0].BirthPlace_Desc;
  return res;
};

export const getSex = async (dispatch) => {
  dispatch(getOptionStart());
  try {
    await Promise.allSettled([
      authFetch.get(`/option/sex`),
      authFetch.get(`/option/birth-type`),
      authFetch.get(`/option/birth-order`),
      authFetch.get(`/option/birth-place`),
      authFetch.get(`/option/cause-of-death`),
    ])
      .then((results) => {
        // const [repos, followers] = results;
        const status = "fulfilled";
        if (results[0].status === status) {
          const newRes = results[0].value.data.map((item, i) => {
            return { label: item.gender, value: item.Gender_ID };
          });
          dispatch(getSexOptionSuccess(newRes));
        }
        if (results[1].status === status) {
          const newRes = results[1].value.data.map((item, i) => {
            return { label: item.Description, value: item.Birth_Type_ID };
          });
          dispatch(getTypeOptionSuccess(newRes));
        }
        if (results[2].status === status) {
          const newRes = results[2].value.data.map((item, i) => {
            return { label: item.Desc, value: item.Birth_Order_ID };
          });
          dispatch(getOrderOptionSuccess(newRes));
        }
        if (results[3].status === status) {
          const newRes = results[3].value.data.map((item, i) => {
            return { label: item.BirthPlace_Desc, value: item.BirthPlace_ID };
          });
          dispatch(getPlaceOptionSuccess(newRes));
        }
        if (results[4].status === status) {
          const newRes = results[4].value.data.map((item, i) => {
            return { label: item.Description, value: item.id };
          });
          dispatch(getCauseOptionSuccess(newRes));
        }
        // console.log(results);
      })
      .catch((err) => console.log(err));
  } catch (error) {
    dispatch(getOptionFailure());
    console.log(error);
  }
  // clearAlert(dispatch);
};

export const getAllBirthRegStats = async () => {
  const { data } = await authFetch.get(
    `/birth-registration/stats/all-registration`
  );
  return data;
};
export const getAllBirthModificationStats = async () => {
  const { data } = await authFetch.get(
    `/modification/birth-registration/all-stats`
  );
  return data;
};
export const getAllBirthNotificationStats = async () => {
  const { data } = await authFetch.get(`birth-notification/all-stats`);
  return data;
};
export const getAllDeathModificationStats = async () => {
  const { data } = await authFetch.get(
    `/modification/death-registration/all-stats`
  );
  return data;
};
export const getAllAttestationModificationStats = async () => {
  const { data } = await authFetch.get(
    `/modification/birth-attestation/all-stats`
  );
  return data;
};
export const getAllStateCommissionersBirthRegistrationStats = async () => {
  const { data } = await authFetch.get(
    `/birth-registration/stats/all-registration`
  );
  return data;
};
export const getAllStateCommissionersBirthNotificationsStats = async () => {
  const { data } = await authFetch.get(
    `/birth-registration/stats/all-registration`
  );
  return data;
};
export const getAllStateCommissionersModificationsStats = async () => {
  const { data } = await authFetch.get(
    `/birth-registration/stats/all-registration`
  );
  return data;
};
export const getAllStateCommissionersAttestationModificationsStats = async () => {
  const { data } = await authFetch.get(
    `/birth-registration/stats/all-registration`
  );
  return data;
};
export const getAllStateCommissionersDeathModificationsStats = async () => {
  const { data } = await authFetch.get(
    `/birth-registration/stats/all-registration`
  );
  return data;
};
export const getAllStateCommissionersBirthAttestationStats = async () => {
  const { data } = await authFetch.get(`/birth-attestation/stats`);
  return data;
};
export const getAllStateCommissionersDeathRegistrationStats = async () => {
  const { data } = await authFetch.get(`/death-registration/stats`);
  return data;
};
export const getLiveVsStillBirthStatsByMonth = async () => {
  const { data } = await authFetch.get(
    `/birth-registration/stats/sc/birth-stillbirth?period=month`
  );
  return data;
};
export const getBirthModificationsStatsByMonth = async () => {
  const { data } = await authFetch.get(
    `/birth-registration/stats/sc/birth-stillbirth?period=month`
  );
  return data;
};
export const getAttestationModificationsStatsByMonth = async () => {
  const { data } = await authFetch.get(
    `/birth-registration/stats/sc/birth-stillbirth?period=month`
  );
  return data;
};
export const getDeathModificationsStatsByMonth = async () => {
  const { data } = await authFetch.get(
    `/birth-registration/stats/sc/birth-stillbirth?period=month`
  );
  return data;
};
export const getBirthAttestationByMonth = async () => {
  const { data } = await authFetch.get(
    `/birth-attestation/stats/sc/birth-graph?period=month`
  );
  return data;
};
export const getDeathByMonth = async () => {
  const { data } = await authFetch.get(
    `/death-registration/stats/sc/death-graph?period=month`
  );
  return data;
};
export const getDeathByYear = async () => {
  const { data } = await authFetch.get(
    `/death-registration/stats/sc/death-graph?period=year`
  );
  return data;
};
export const getBirthRegistrationsByGender = async () => {
  const { data } = await authFetch.get(
    `/birth-registration/stats/sc/birth-by-gender`
  );
  return data;
};
export const getBirthNotificationsByGender = async () => {
  const { data } = await authFetch.get(
    `/birth-registration/stats/sc/birth-by-gender`
  );
  return data;
};
export const getBirthModificationsByGender = async () => {
  const { data } = await authFetch.get(
    `/birth-registration/stats/sc/birth-by-gender`
  );
  return data;
};
export const getAttestationModificationsByGender = async () => {
  const { data } = await authFetch.get(
    `/birth-registration/stats/sc/birth-by-gender`
  );
  return data;
};
export const getDeathModificationsByGender = async () => {
  const { data } = await authFetch.get(
    `/birth-registration/stats/sc/birth-by-gender`
  );
  return data;
};
export const getBirthAttestationsByGender = async () => {
  const { data } = await authFetch.get(
    `/birth-attestation/stats/sc/birth-by-gender`
  );
  return data;
};
export const getDeathRegistrationsByGender = async () => {
  const { data } = await authFetch.get(
    `/death-registration/stats/sc/death-by-gender`
  );
  return data;
};
export const getStillBirthRegistrationsByGender = async () => {
  const { data } = await authFetch.get(`/stillbirth/stats/sc/birth-by-gender`);
  return data;
};
export const getAssistedVsSelfStats = async () => {
  const { data } = await authFetch.get(
    `/birth-registration/stats/sc/assisted-vs-self`
  );
  return data;
};
export const getAssistedVsSelfModificationsStats = async () => {
  const { data } = await authFetch.get(
    `/birth-registration/stats/sc/assisted-vs-self`
  );
  return data;
};
export const getAssistedVsSelfAttestationModificationsStats = async () => {
  const { data } = await authFetch.get(
    `/birth-registration/stats/sc/assisted-vs-self`
  );
  return data;
};
export const getAssistedVsSelDeathfModificationsStats = async () => {
  const { data } = await authFetch.get(
    `/birth-registration/stats/sc/assisted-vs-self`
  );
  return data;
};
export const getAssistedVsSelfBirthAttestationStats = async () => {
  const { data } = await authFetch.get(
    `/birth-attestation/stats/sc/assisted-vs-self`
  );
  return data;
};
export const getDeathAssistedVsSelfStats = async () => {
  const { data } = await authFetch.get(
    `/death-registration/stats/sc/assisted-vs-self`
  );
  return data;
};
export const getTopRegByLGAStats = async () => {
  const { data } = await authFetch.get(
    `/birth-registration/stats/sc/top-reg-by-lga`
  );
  return data;
};
export const getTopNotificationsByLGAStats = async () => {
  const { data } = await authFetch.get(
    `/birth-registration/stats/sc/top-reg-by-lga`
  );
  return data;
};
export const getTopModificationsByLGAStats = async () => {
  const { data } = await authFetch.get(
    `/birth-registration/stats/sc/top-reg-by-lga`
  );
  return data;
};
export const getTopAttestationModificationsByLGAStats = async () => {
  const { data } = await authFetch.get(
    `/birth-registration/stats/sc/top-reg-by-lga`
  );
  return data;
};
export const getTopAttestationaByLGAStats = async () => {
  const { data } = await authFetch.get(
    `/birth-attestation/stats/sc/top-reg-by-lga`
  );
  return data;
};
export const getTopdeathRegByLGAStats = async () => {
  const { data } = await authFetch.get(
    `/death-registration/stats/sc/top-reg-by-lga`
  );
  return data;
};
export const getTopStillBirthRegByLGAStats = async () => {
  const { data } = await authFetch.get(`/stillbirth/stats/sc/top-reg-by-lga`);
  return data;
};
export const getBirthModificationsStatsByYear = async () => {
  const { data } = await authFetch.get(
    `/birth-attestation/stats/sc/birth-graph?period=year`
  );
  return data;
};
export const getAttestationModificationsStatsByYear = async () => {
  const { data } = await authFetch.get(
    `/birth-attestation/stats/sc/birth-graph?period=year`
  );
  return data;
};
export const getDeathModificationsStatsByYear = async () => {
  const { data } = await authFetch.get(
    `/birth-attestation/stats/sc/birth-graph?period=year`
  );
  return data;
};
export const getLiveVsStillBirthStatsByYear = async () => {
  const { data } = await authFetch.get(
    `/birth-registration/stats/sc/birth-stillbirth?period=year`
  );
  return data;
};
export const getBirthAttestationStatsByYear = async () => {
  const { data } = await authFetch.get(
    `/birth-attestation/stats/sc/birth-graph?period=year`
  );
  return data;
};
export const getUnder5BirthRegStats = async () => {
  const { data } = await authFetch.get(
    `/birth-registration/stats/sc/under-5-birth-registration`
  );
  return data;
};
export const getBirthNotificationByAgeStats = async () => {
  const { data } = await authFetch.get(
    `/birth-registration/stats/sc/under-5-birth-registration`
  );
  return data;
};
export const getBirthMotidicationByAgeStats = async () => {
  const { data } = await authFetch.get(
    `/birth-registration/stats/sc/under-5-birth-registration`
  );
  return data;
};
export const getAttestationMotidicationByAgeStats = async () => {
  const { data } = await authFetch.get(
    `/birth-registration/stats/sc/under-5-birth-registration`
  );
  return data;
};
export const getDeathMotidicationByAgeStats = async () => {
  const { data } = await authFetch.get(
    `/birth-registration/stats/sc/under-5-birth-registration`
  );
  return data;
};
export const getBirthAttestationByAgeStats = async () => {
  const { data } = await authFetch.get(
    `/birth-attestation/stats/sc/birth-registration-by-age`
  );
  return data;
};
export const getDeathRegByAgeStats = async () => {
  const { data } = await authFetch.get(
    `/death-registration/stats/sc/registration-by-age`
  );
  return data;
};
export const getMothersAgeAtBirthStats = async () => {
  const { data } = await authFetch.get(
    `/stillbirth/stats/sc/mother-age-birth-of-child`
  );
  return data;
};
export const getCompareStats = async () => {
  const { data } = await authFetch.get(
    `/birth-registration/stats/sc/compare-stats`
  );
  return data;
};
export const getBirthAttestationCompareStats = async () => {
  const { data } = await authFetch.get(
    `/birth-attestation/stats/sc/compare-stats`
  );
  return data;
};
export const getBirthNotificationsCompareStats = async () => {
  const { data } = await authFetch.get(
    `/birth-attestation/stats/sc/compare-stats`
  );
  return data;
};
export const getBirthMotidicationsCompareStats = async () => {
  const { data } = await authFetch.get(
    `/birth-attestation/stats/sc/compare-stats`
  );
  return data;
};
export const getDeathCompareStats = async () => {
  const { data } = await authFetch.get(
    `/death-registration/stats/sc/compare-stats`
  );
  return data;
};
export const getCompareStillBirthStats = async () => {
  const { data } = await authFetch.get(`/stillbirth/stats/sc/compare-stats`);
  return data;
};
export const getBirthStats = async () => {
  const { data } = await authFetch.get(`/statistics/birth`);
  return data;
};
export const getAllDeathRegStats = async () => {
  const { data } = await authFetch.get(
    `/death-registration/stats/all-registration`
  );
  return data;
};
export const getAllAttestationRegStats = async () => {
  const { data } = await authFetch.get(
    `/birth-attestation/stats/all-registration`
  );
  return data;
};
export const getAllAttestationwithoutNinStats = async () => {
  const { data } = await authFetch.get(`self-attestation/no-nin/all-stats`);
  return data;
};
export const getAllBirthRegRecords = async ({
  result_per_page,
  page,
  type,
  search,
  self,
}) => {
  const queryParams = {
    result_per_page: result_per_page || null,
    page: page || null,
    type: type || null,
    search: search || null,
    self: self || null,
  };
  const { data } = await authFetch.get(
    "/birth-registration/list-all-registration",
    { params: queryParams }
  );

  return data;
};
export const getAllBirthModificationRecords = async ({
  result_per_page,
  page,
  type,
  search,
}) => {
  const queryParams = {
    result_per_page: result_per_page || null,
    page: page || null,
    type: type || null,
    search: search || null,
  };
  const { data } = await authFetch.get("modification/birth-registration", {
    params: queryParams,
  });

  return data;
};
export const getAllBirthNotificationRecords = async ({
  result_per_page,
  page,
  type,
  search,
}) => {
  const queryParams = {
    result_per_page: result_per_page || null,
    page: page || null,
    type: type || null,
    search: search || null,
  };
  const { data } = await authFetch.get("birth-notification", {
    params: queryParams,
  });

  return data;
};
export const getAllAttestationWithoutNINRecords = async ({
  result_per_page,
  page,
  type,
  search,
}) => {
  const queryParams = {
    result_per_page: result_per_page || null,
    page: page || null,
    type: type || null,
    search: search || null,
  };
  const { data } = await authFetch.get("self-attestation/no-nin", {
    params: queryParams,
  });

  return data;
};
export const getAllDeathModificationRecords = async ({
  result_per_page,
  page,
  type,
  search,
}) => {
  const queryParams = {
    result_per_page: result_per_page || null,
    page: page || null,
    type: type || null,
    search: search || null,
  };
  const { data } = await authFetch.get("modification/death-registration", {
    params: queryParams,
  });

  return data;
};
export const getAllAttestationModificationRecords = async ({
  result_per_page,
  page,
  type,
  search,
}) => {
  const queryParams = {
    result_per_page: result_per_page || null,
    page: page || null,
    type: type || null,
    search: search || null,
  };
  const { data } = await authFetch.get("modification/birth-attestation", {
    params: queryParams,
  });

  return data;
};
export const getAllDeathRegRecords = async ({
  result_per_page,
  page,
  type,
  search,
  self,
}) => {
  const queryParams = {
    result_per_page: result_per_page || null,
    page: page || null,
    type: type || null,
    search: search || null,
    self: self || null,
  };
  const { data } = await authFetch.get(
    "/death-registration/list-all-registration",
    { params: queryParams }
  );

  return data;
};
export const getAllAttestationRegRecords = async ({
  result_per_page,
  page,
  type,
  search,
  self,
}) => {
  const queryParams = {
    result_per_page: result_per_page || null,
    page: page || null,
    type: type || null,
    search: search || null,
    self: self || null,
  };
  const { data } = await authFetch.get(
    "/birth-attestation/list-all-registration",
    { params: queryParams }
  );

  return data;
};

export const getPermissions = async () => {
  const { data } = await authFetch.get(`/option/permissions`);
  return data;
};

export const getAllStates = async () => {
  const { data } = await authFetch.get(`/option/states/63`);
  return data;
};
export const getLgaByStateId = async (stateId) => {
  const { data } = await authFetch.get(`/option/lga/${stateId}`);
  return data;
};
export const getCenterByLgaId = async (lgaId) => {
  const { data } = await authFetch.get(`/option/regcenter/${lgaId}`);
  return data;
};

export const updateUserApi = async (payload) => {
  const { data } = await authFetch.patch(`/users`, payload);
  return data;
};
export const createUserApi = async (payload) => {
  const { data } = await authFetch.post(`/users`, payload);
  return data;
};

export const getAllUsersApi = async ({
  result_per_page,
  search,
  page,
  role_id,
  state_id,
  lga_id,
  regcenter_id,
}) => {
  const queryParams = {
    result_per_page: result_per_page || null,
    page: page || null,
    role_id: role_id || null,
    search: search || null,
    state_id: state_id || null,
    lga_id: lga_id || null,
    regcenter_id: regcenter_id || null,
  };
  const { data } = await authFetch.get(`/users`, { params: queryParams });
  return data;
};

export const getSingleUserApi = async (payload) => {
  const { data } = await authFetch.get(`/users/single/${payload}`);
  return data;
};

export const getAllUsersUnderRoleApi = async ({
  result_per_page,
  search,
  page,
  role_id,
  state_id,
  lga_id,
  regcenter_id,
}) => {
  const queryParams = {
    result_per_page: result_per_page || null,
    page: page || null,
    search: search || null,
    state_id: +role_id === Roles.StateDirector ? state_id : null,
    lga_id: +role_id === Roles.DCR ? lga_id : null,
    regcenter_id: +role_id === Roles.Registrar ? regcenter_id : null,
  };
  const { data } = await authFetch.get(`/users-by-role-location`, {
    params: queryParams,
  });
  return data;
};

export const resetUsersPassword = async (payload) => {
  const { data } = await authFetch.get(`/users/reset-password/${payload}`);
  return data;
};

export const changeUserStatus = async ({ id, status }) => {
  const { data } = await authFetch.post(`/users/change-status`, {
    account_id: parseInt(id),
    status: status,
  });
  return data;
};
export const unlinkUserAccount = async (payload) => {
  const { data } = await authFetch.get(`/users/unlink-device/${payload}`);
  return data;
};

export const getSingleBirthReg = async (id) => {
  const { data } = await authFetch.get(`/birth-registration/${id}`);
  return data;
};
export const getSingleBirthModification = async (id) => {
  const { data } = await authFetch.get(
    `/modification/birth-registration/single-mod/${id}`
  );
  return data;
};
export const getSingleAttestationModification = async (id) => {
  const { data } = await authFetch.get(
    `/modification/birth-attestation/single-mod/${id}`
  );
  return data;
};
export const getSingleDeathModification = async (id) => {
  const { data } = await authFetch.get(
    `/modification/death-registration/single-mod/${id}`
  );
  return data;
};
export const getSingleBirthNotification = async (id) => {
  const { data } = await authFetch.get(`/birth-notification/${id}`);
  return data;
};
export const getSingleDeathReg = async (id) => {
  const { data } = await authFetch.get(`/death-registration/${id}`);
  return data;
};
export const getSingleAttestationReg = async (id) => {
  const { data } = await authFetch.get(`/birth-attestation/${id}`);
  return data;
};
export const getSingleAttestationWithoutNinReg = async (id) => {
  const { data } = await authFetch.get(`/self-attestation/no-nin/${id}`);
  return data;
};

export const ApproveBirthRegistrationsApi = async (id) => {
  const { data } = await authFetch.get(
    `/dcr/registrations/birth/approve/${id}`
  );
  return data;
};
export const ApproveDeathRegistrationsApi = async (id) => {
  const { data } = await authFetch.get(
    `/dcr/registrations/death/approve/${id}`
  );
  return data;
};
export const ApproveBirthAttestationApi = async (id) => {
  const { data } = await authFetch.patch(
    `/state-director/birth-attestation/approve/${id}`
  );
  return data;
};
export const ApproveBirthModificationApi = async (id) => {
  const { data } = await authFetch.patch(
    `/state-director/modification/birth-registration/approve-mod/${id}`
  );
  return data;
};
export const ApproveDeathModificationApi = async (id) => {
  const { data } = await authFetch.patch(
    `/state-director/modification/death-registration/approve-mod/${id}`
  );
  return data;
};
export const ApproveBirthAttestationModificationApi = async (id) => {
  const { data } = await authFetch.patch(
    `/state-director/modification/birth-attestation/approve-mod/${id}`
  );
  return data;
};

export const QueryBirthRegistrationsApi = async (payload) => {
  const { data } = await authFetch.post(
    `/dcr/registrations/birth/reject/`,
    payload
  );
  return data;
};
export const QueryDeathRegistrationsApi = async (payload) => {
  const { data } = await authFetch.post(
    `/dcr/registrations/death/reject/`,
    payload
  );
  return data;
};
export const QueryBirthAttestationApi = async (payload) => {
  const { data } = await authFetch.post(
    `/state-director/birth-attestation/reject`,
    payload
  );
  return data;
};
export const QueryBirthModficationApi = async (PAYLOAD) => {
  const { data } = await authFetch.patch(
    `/state-director/modification/birth-registration/reject-mod`,
    PAYLOAD
  );
  return data;
};
export const QueryDeathModficationApi = async (PAYLOAD) => {
  const { data } = await authFetch.patch(
    `/state-director/modification/death-registration/reject-mod`,
    PAYLOAD
  );
  return data;
};
export const QueryBirthAttestationModficationApi = async (PAYLOAD) => {
  const { data } = await authFetch.patch(
    `/state-director/modification/birth-attestation/reject-mod`,
    PAYLOAD
  );
  return data;
};
export const getFilePreviewUrl = async (key) => {
  const { data } = await authFetch.get(`/get-preview-url/${key}`);
  return data;
};
