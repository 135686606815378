import { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link } from "react-router-dom";
import { Paginator } from "primereact/paginator";
import { Tooltip } from "primereact/tooltip";
import {
  getAllBirthRegRecords,
  getAllBirthRegStats,
} from "../../redux/apiCalls";
import CardWidget from "../../components/widgets/cards";
import { Toast } from "primereact/toast";
import {
  approvalStatus,
  dateTemplate,
  dobTemplate,
  genderTemplate,
  regTypeFormat,
  RoleName,
} from "../../utils";
import FilterHeader from "../../components/FilterHeader";
import { selfFilterOptions, statusFilterOptions } from "../../helpers/constants";

const AllBirthRegistrations = () => {
  const toast = useRef(null);
  const [statisticsLoading, setStatisticsLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [statistics, setStatistics] = useState(null);
  const [first, setFirst] = useState(0);
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(20);
  const [pagination, setPagination] = useState([]);
  const [filters, setFilters] = useState({
    self: "",
    type: "",
    search: "",
  });
  let pageIndexOffset = 0;
  const pageStartIndex = page > 1 ? (page - 1) * rows : 0;
  const onPageChange = (event) => {
    setFirst(event.first);
    setPage(event.page + 1); // PrimeReact uses 0-based page index
    setRows(event.rows);
    pageIndexOffset = event.first;
  };

  const [registrations, setRegistrations] = useState([]);
  const getStatistics = async () => {
    try {
      const data = await getAllBirthRegStats();
      setStatistics(data);
      setStatisticsLoading(false);
    } catch (error) {
      console.error(error);
      setStatisticsLoading(false);
    }
  };
  const getRecords = async () => {
    setLoading(true);
    try {
      const data = await getAllBirthRegRecords({
        result_per_page: rows,
        page,
        type: filters.type,
        search: filters.search,
        self: filters.self
      });

      setRegistrations(data?.result);
      setPagination(data?.pagination);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getStatistics();
  }, []);

  useEffect(() => {
    getRecords();
  }, [filters, page, rows]);

  const stats = [
    {
      name: "Total Registrations",
      count: statistics?.total,
      color: "#551CF6",
    },
    {
      name: "Pending Registrationssss",
      count: statistics?.Pending,
      color: "#FFDC27",
    },
    {
      name: "Approved Registrations",
      count: statistics?.Approved,
      color: "#289004",
    },
    {
      name: "Queried Registrations",
      count: statistics?.Queried,
      color: "#FF2727",
    },
  ];

  const statusBodyTemplate2 = (rowData) => {
    return (
      <Link
        className={`btn btn-primary text-primary font-bold`}
        to={`view/${rowData.Birth_Reg_ID}`}
      >
        VIEW
      </Link>
    );
  };

  const nameTemplate = (rowData) => {
    return (
      <p className={`font-bold`}>
        {rowData.childData?.firstname ?? ""} {rowData.childData?.surname ?? ""}
      </p>
    );
  };

  const approvalStatusTemplate = (rowData) => {
    let status;
    switch (rowData?.Approval_Status) {
      case approvalStatus.APPROVED:
        status = "Approved";
        break;
      case approvalStatus.PENDING:
        status = "Pending";
        break;
      case approvalStatus.QUERIED:
        status = "Queried";
        break;
      default:
        status = "Unknown";
    }
    return <p>{status}</p>;
  };

  const SN = (rowData) => {
    const sn = registrations.findIndex((items) => items === rowData);

    return <p>{pageStartIndex + sn + 1 + pageIndexOffset}</p>;
  };

  const filterGroups = [
    {
      name: "type",
      label: "Status",
      type: "dropdown",
      options: statusFilterOptions,
      optionLabel: "name",
      optionValue: "id",
    },
    {
      name: "self",
      label: "Type",
      type: "dropdown",
      options: selfFilterOptions,
      optionLabel: "name",
      optionValue: "id",
    },
    { name: "search", label: "Search", type: "search" },
  ];
  const handleFilterChange = (updatedFilters) => {
    setFilters(updatedFilters);
  };
  const renderHeader1 = () => {
    return (
      <>
        <p className="capitalize">
          {filters?.status === "" ? "All" : filters?.status ?? "All"}{" "}
          Registrations -{pagination?.total}
        </p>
        <FilterHeader
          filters={filterGroups}
          onFilterChange={handleFilterChange}
        />
      </>
    );
  };

  const header1 = renderHeader1();
  const dt = useRef(null);

  return (
    <>
      <Toast ref={toast} position="top-center" />
      <div className=" mt-2">
        <div className="">
          <div className="mt-3 w-full m-0 grid gap-4 ">
            {stats.map((item, index) => {
              const { name, color, count } = item;
              return (
                <CardWidget
                  loading={statisticsLoading}
                  key={index}
                  name={name}
                  color={color}
                  count={count}
                />
              );
            })}
          </div>

          <div className="card mt-6 p-3 ">
            {/* <div className="mb-3 flex align-items-center justify-content-between p-3">
              <Button
                label="Go back"
                icon="pi pi-arrow-left"
                className="p-button-sm"
                onClick={goBack}
              />
              <span className="text-xl font-medium text-900">
                Registration List
              </span>
              <div className="flex align-items-center export-buttons">
                <Button
                  type="button"
                  icon="pi pi-file-excel"
                  onClick={exportExcel}
                  className="p-button-success mr-2"
                  data-pr-tooltip="XLS"
                />
                <Button
                  type="button"
                  icon="pi pi-file-pdf"
                  onClick={exportPdf}
                  className="p-button-warning mr-2"
                  data-pr-tooltip="PDF"
                />
              </div>
            </div> */}

            <DataTable
              value={registrations}
              ref={dt}
              loading={loading}
              stripedRows
              responsiveLayout="stack"
              header={header1}
              id="p-table"
            >
              <Column body={SN} header="S/N"></Column>
              <Column
                field="Name"
                body={nameTemplate}
                header="Full Names"
              ></Column>
              <Column field="Certificate_No" header="Registration ID"></Column>
              <Column
                field="gender"
                header="Gender"
                body={(rowData) => genderTemplate(rowData.childData?.gender)}
              ></Column>
              <Column
                header="Date of Birth"
                body={(rowData) => dobTemplate(rowData?.childData?.birth_date)}
              ></Column>
              <Column
                body={(rowData) => dateTemplate(rowData?.initiatedAt)}
                header="Date Initiated"
              ></Column>
              <Column
                body={(rowData) => dateTemplate(rowData?.Date_Registerred)}
                header="Registration Date"
              ></Column>
              <Column body={regTypeFormat} header="Registration Type"></Column>
              <Column
                field="Approval_Status"
                body={approvalStatusTemplate}
                header="Status"
              ></Column>
              <Column
                body={(rowData) => <div>
                  {rowData?.Registered_ByData ? (
                    <>
                      <div className="font-semibold text-xs">
                        {rowData.Registered_ByData.UserName}
                      </div>
                      <div className="hidden text-sm text-muted-foreground md:inline">
                        <span className="text-xs">
                          Role: {RoleName(rowData.Registered_ByData.Role_ID)}
                        </span>
                      </div>
                    </>
                  ) : (
                    <span className="font-semibold text-xs">Self</span>
                  )}

                </div>}
                header="Registered By"
              ></Column>

              <Column field="" header="Action" body={statusBodyTemplate2} />
            </DataTable>
            <Tooltip target=".export-buttons>button" position="bottom" />
            <Paginator
              first={first}
              rows={rows}
              totalRecords={pagination?.total}
              rowsPerPageOptions={[10, 20, 50, 100]}
              // page={page}
              onPageChange={onPageChange}
            ></Paginator>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllBirthRegistrations;
